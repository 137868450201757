import React, {
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  Container, FormSection, Input, Label, Button,
} from '../NewKanbanBoardStyles';
import CustomAlert from '../../CustomAlert';
import useAlert from '../../../hooks/useAlert';

export default function KanbanBoardSettings({ currentKanban, onSave }) {
  const [boardName, setBoardName] = useState(currentKanban.name || '');
  const [boardDescription, setBoardDescription] = useState(currentKanban.description || '');
  const [columns, setColumns] = useState([]);

  const { alert, showAlert, hideAlert } = useAlert();

  useEffect(() => {
    setBoardName(currentKanban.name);
    setBoardDescription(currentKanban.description);
    setColumns(Object.values(currentKanban.columns || []));
  }, [currentKanban]);

  useEffect(() => {
    onSave({
      name: boardName,
      description: boardDescription,
      columns,
    });
  }, [boardName, boardDescription, columns]);

  const handleColumnChange = (index, field, value) => {
    if (field === 'position') {
      const newPosition = parseInt(value, 10);
      const isDuplicate = columns.some((col, idx) => col.position === newPosition && idx !== index);

      if (isDuplicate) {
        showAlert('Essa posição já foi selecionada em outra coluna. Selecione uma nova', 'info', 5000);
        return;
      }
    }

    setColumns((prevColumns) => {
      const updatedColumns = [...prevColumns];
      updatedColumns[index] = { ...updatedColumns[index], [field]: value };
      return updatedColumns;
    });
  };

  const addColumn = () => {
    setColumns([...columns, { title: '', position: columns.length + 1 }]);
  };

  const handleRemoveColumn = (index) => {
    setColumns(columns.filter((_, idx) => idx !== index));
  };

  return (
    <Container>
      <CustomAlert alert={alert} hideAlert={hideAlert} />

      <FormSection>
        <Label>Nome do quadro</Label>
        <Input
          type="text"
          value={boardName}
          onChange={(e) => setBoardName(e.target.value)}
          placeholder="Digite o nome do quadro"
        />
      </FormSection>

      <FormSection>
        <Label>Descrição</Label>
        <Input
          type="text"
          placeholder="Digite uma breve descrição do quadro"
          value={boardDescription}
          onChange={(e) => setBoardDescription(e.target.value)}
        />
      </FormSection>

      <FormSection>
        <Label>Colunas</Label>
        {columns.map((column, index) => (
          <div
            key={index}
            style={{
              display: 'flex', marginBottom: '10px',
            }}
          >
            <Input
              type="text"
              placeholder="Nome da coluna"
              value={column.title}
              onChange={(e) => handleColumnChange(index, 'title', e.target.value)}
            />
            <Input
              type="number"
              placeholder="Ordem"
              value={column.position}
              onChange={(e) => handleColumnChange(index, 'position', parseInt(e.target.value, 10))}
              style={{ width: '80px', marginLeft: '10px' }}
            />
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => handleRemoveColumn(index)}
              style={{ marginLeft: '10px' }}
            >
              Remover
            </Button>
          </div>
        ))}
        <Button type="button" onClick={addColumn}>Adicionar coluna</Button>
      </FormSection>

    </Container>
  );
}

KanbanBoardSettings.propTypes = {
  currentKanban: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    columns: PropTypes.objectOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        position: PropTypes.number.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  onSave: PropTypes.func.isRequired,
};
