import styled from 'styled-components';

export const Container = styled.div`
display: flex;
flex-direction: column;
width: 100%;
margin: 0 5px 10px 5px;
margin-bottom: 10px;
align-items: flex-start;

.custom-linkify-link {
  display: block;
  max-width: 300px;
}

.message-content-div {
  border-bottom: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: auto;
  max-width: 500px;
  min-width: 20%;
  word-wrap: break-word;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);

  .message-menu-button {
    background-color: transparent;
    color: ${({ theme }) => theme.iconsColor};
    align-self: flex-end;
    margin-top: -5px;
    margin-bottom: -10px;
  }
  }

  .message-deleted-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    margin-top: 10px;
  }

  span {
    font-size: 14px;
    color: ${({ theme }) => theme.secondaryFontColor};
  }

  audio {
    max-width: 100%;
  }

  embed {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .header-message-div {
    display: flex;
    align-items: center;

    span {
      margin-left: 5px;
      font-size: 12px;
    }
  }

    .quoted-message-box {
    display: flex;
    align-items: flex-start;
    margin: 10px 0;
    border: 1px solid #EEEEEE;
    border-radius: 10px;

    .left-bar {
    width: 5px;
    background-color: ${({ theme }) => theme.sidebarQuotedMessageColor};
    border-radius: 10px 0 0 10px;
    height: 100%;
  }

  .quoted-message-content {
    background-color: #f5f5f5;
    border-radius: 0 10px 10px 0;
    padding: 10px;
    max-width: 300px;
    width: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .sender-name {
    font-weight: bold;
    color: ${({ theme }) => theme.sidebarQuotedMessageColor};
    margin-bottom: 4px;
  }
  .quoted-message-text {
    color: #333;
    font-size: 14px;
    p {
      max-height: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .media-quoted-message-box {
    width: 100%;
    margin-top: 5px;
    height: auto;
    max-height: 300px;
  }

  .quoted-message-media-div {
    padding: 5px;
    max-width: 80%;
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .quoted-media {
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      height: auto;
    }
  }

  .message-media-div {
    padding: 5px;
    max-width: 80%;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

    button {
      width: 500px;
      height: 200px;
      border-radius: 4px;

      svg {
        color: ${({ theme }) => theme.secondaryColor};
        position: absolute;
        background-color: ${({ theme }) => theme.inputBackground};
        border-radius: 6px;
        bottom: 36%;
        right: 38%;
        font-size: 60px;
      }
    }
  }

  .audio-player-div {
    display: flex;
    align-items: center;
    gap: 10px;
    max-width: 500px;
    background-color: ${({ theme }) => theme.buttonBackground};
    border-radius: 12px;
    padding: 8px 12px;

    audio {
        flex-grow: 1;
    }

    button {
        background-color: transparent;
        padding: 4px;
        border-radius: 50%;
        transition: background-color 0.2s ease;

        svg {
            color: ${({ theme }) => theme.iconsColor};
        }

        &:hover {
            filter: brightness(0.7);
        }
    }
}

    .audio-transcription-div {
      background-color: ${({ theme }) => theme.buttonBackground};
      border-left: 4px solid #014F6D;
      padding: 16px;
      margin-top: 12px;
      border-radius: 6px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    }

    .transcription-text {
      font-style: italic;
      font-size: 1rem;
      color: #333;
      line-height: 1.5;
      margin: 0;
    }

  .time-and-confirmation-span {
    margin-top: 5px;
    display: flex;
    align-items: center;
    text-align: right;
    justify-content: end;
  }

  .message-reacted-div {
    align-self: flex-end;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px;
    margin-top: 5px;
    border-radius: 50%;
    background-color: #C7E0B3;
    font-size: 15px;
    border: 1px solid #AEC49D;
  }
`;

export const ContactCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  padding: 20px 60px;
  border: 1px solid #AEBAC1;
  border-radius: 12px;
  background: linear-gradient(135deg, #F5F7FA, #E4EBF1);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  max-width: 300px;
  text-align: center;

  h1 {
    font-size: 1.5rem;
    color: ${({ theme }) => theme.secondaryColor};
    font-weight: 600;
    margin-bottom: 15px;
    line-height: 1.2;
  }

  span {
    font-size: 1rem;
    color: ${({ theme }) => theme.blueTelein};
    padding: 5px 12px;
    border-radius: 5px;
    margin-bottom: 8px;
    font-weight: 500;
  }

  span {
    margin-bottom: 5px;
  }
`;
