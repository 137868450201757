/* eslint-disable jsx-a11y/label-has-associated-control */
import AttachFileIcon from '@mui/icons-material/AttachFile';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VideocamIcon from '@mui/icons-material/Videocam';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import ImageIcon from '@mui/icons-material/Image';
import { useTheme } from 'styled-components';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import {
  Tooltip,
} from '@mui/material';

import PropTypes from 'prop-types';

import { useContext, useState } from 'react';
import { baseUrl, getRequest, formatPhoneNumber } from '../../utils/services';
import { Container } from './styles';
import { ChatContext } from '../../context/ChatContext';
import PopOverMenu from '../PopOverMenu';
import CustomDialog from '../CustomDialog';
import ScheduleMessageDialog from '../ScheduleMessageDialog';
import useWindowSize from '../../hooks/useWindowSize';

import TransferTicketSection from '../TransferTicketSection';
import { AuthContext } from '../../context/AuthContext';
import { useErrors } from '../../context/ErrorContext';

export default function ChatHeader({
  contactName,
  isOnline,
  ipbxNumber,
  leadOrigin,
  attendantName,
  profileImageURL = null,
}) {
  const {
    currentChat,
    departments,
    selectedDepartment,
    selectedAttendant,
    setCurrentChat,
    handleInputMessageChange,
    setSelectedImageToSend,
    setSelectedVideoToSend,
    setSelectedDocumentToSend,
    setScheduledMessages,
    updateTicketToClosed,
    handleTransferTicket,
    handleCreateTicketClosedNotification,
    openTransferModal,
    setOpenTransferModal,
    setIsEditingProfileChat,
    chatTags,
    setChatTags,
  } = useContext(ChatContext);

  const { user } = useContext(AuthContext);

  const { width } = useWindowSize();

  const theme = useTheme();

  const { setErrorMessage } = useErrors();

  const [openTicketModal, setOpenTicketModal] = useState(false);

  const [openScheduleMessage, setOpenScheduleMessage] = useState(false);

  const handleOpenTransferModal = () => {
    setOpenTransferModal(true);
  };

  const handleCloseTransferModal = () => {
    setOpenTransferModal(false);
  };

  const handleOpenScheduleMessage = async () => {
    const response = await getRequest(`${baseUrl}/messages/scheduled/${currentChat.sender}/${currentChat.fromMsg}`);

    if (response.error) {
      return setErrorMessage('Não foi possível carregar as mensagens agendadas! Entre em contato o suporte.');
    }

    setScheduledMessages(response);
    setOpenScheduleMessage(true);
  };

  const handleCloseScheduleMessage = () => {
    setOpenScheduleMessage(false);
  };

  const handleOpenTicketModal = () => {
    setOpenTicketModal(true);
  };

  const handleCloseTicketModal = () => {
    setOpenTicketModal(false);
  };

  const handleOpenEditProfileChat = async () => {
    const associatedTags = await getRequest(`${baseUrl}/chats/associatedTags/${user.ambiente}/${currentChat.fromMsg}`);

    const availableTags = await getRequest(`${baseUrl}/chats/availableTags/${user.ambiente}`);

    if (associatedTags.error || availableTags.error) {
      setIsEditingProfileChat(true);
      return setErrorMessage('Não foi possível carregar tags! Entre em contato com o suporte.');
    }

    const filteredAvailableTags = availableTags.filter(
      (availableTag) => !associatedTags
        .some((associatedTag) => associatedTag.id === availableTag.id),
    );

    setChatTags({
      ...chatTags,
      associatedTags,
      availableTags: filteredAvailableTags,
    });

    setIsEditingProfileChat(true);
  };

  const handleCloseTicket = () => {
    updateTicketToClosed();
    handleCreateTicketClosedNotification(
      currentChat.sender,
      currentChat.fromMsg,
      user.ambiente,
    );
    setCurrentChat(null);
  };

  const handleBackMenuClick = () => {
    setCurrentChat(null);
  };

  const handleOnConfirmTransferTicket = () => {
    handleTransferTicket(
      selectedAttendant,
      currentChat.sender,
      currentChat.fromMsg,
      selectedDepartment,
      user.ambiente,
    );
    setCurrentChat(null);
  };

  const handleImageUpload = (e) => {
    const { files } = e.target;
    setSelectedImageToSend(files);
  };

  const handleVideoUpload = (e) => {
    const { files } = e.target;
    setSelectedVideoToSend(files);
  };

  const handleDocumentsUpload = (e) => {
    const { files } = e.target;
    setSelectedDocumentToSend(files);
  };

  const currentChatDepartment = departments
    .find((department) => department.id === currentChat.setor_id);

  return (
    <Container>
      <div
        style={{
          height: '100%',
          width: '100%',
        }}
      >
        <button
          type="button"
          aria-label="Voltar para tela inicial"
          onClick={handleBackMenuClick}
          style={{
            width: '50px',
            backgroundColor: theme.buttonBackground,
          }}
        >
          <ArrowBackIcon style={{ color: '#919191' }} />
        </button>

        <button
          aria-label="Botão de perfil do chat"
          className="profile-chat-button"
          onClick={handleOpenEditProfileChat}
          type="button"
        >
          <img src={currentChat.profilePic ? currentChat.profilePic : 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/1024px-User-avatar.svg.png?20201213175635'} alt="Imagem de perfil do usuário" />

          <div
            className="number-and-ipbx-div"
            style={{
              flexDirection: width < 1170 ? 'column' : 'row',
              alignItems: 'start',
            }}
          >
            <h1>{contactName}</h1>

            <span>
              <Tooltip
                arrow
                title="Número do contato"
              >
                {currentChat?.nome ? formatPhoneNumber(currentChat.fromMsg) : ''}
              </Tooltip>
              {isOnline
                ? 'Online'
                : ''}
            </span>
            <Tooltip
              arrow
              title="Número IPBX"
            >
              <span
                className="ipbxNumber-span"
                style={{
                  marginLeft: width < 1170 ? '' : '20px',
                  marginTop: width > 1170 ? '' : '5px',
                }}
              >
                {ipbxNumber}
              </span>
            </Tooltip>
            {leadOrigin && currentChat?.status !== 3 && (
            <Tooltip
              arrow
              title="Origem do lead"
            >
              <span
                className="lead-origin-span"
                style={{
                  marginLeft: width < 1170 ? '' : '20px',
                  marginTop: width > 1170 ? '' : '5px',
                }}
              >
                {leadOrigin}
              </span>
            </Tooltip>
            )}
            {currentChatDepartment?.nome_setor && currentChat?.status !== 3 && (
            <Tooltip
              arrow
              title="Departamento do chamado"
            >
              <span
                className="department-span"
                style={{
                  marginLeft: width < 1170 ? '' : '20px',
                  marginTop: width > 1170 ? '' : '5px',
                }}
              >
                {currentChatDepartment?.nome_setor}
              </span>
            </Tooltip>
            )}
            {attendantName && currentChat?.status !== 3 && (
              <Tooltip
                arrow
                title="Nome do atendente"
              >
                <span
                  className="attendant-name-span"
                  style={{
                    marginLeft: width < 1170 ? '' : '20px',
                    marginTop: width > 1170 ? '' : '5px',
                  }}
                >
                  {attendantName}
                </span>
              </Tooltip>
            )}
          </div>
        </button>
      </div>

      {width > 1150 ? (
        <div className="action-chat-buttons">
          <Tooltip
            arrow
            title="Transferir chamado"
          >
            <button
              type="button"
              aria-label="Tranferir chamado"
              className="transfer-ticket-button"
              onClick={handleOpenTransferModal}
            >
              <SyncAltIcon />
            </button>

            <CustomDialog
              open={openTransferModal}
              onClose={handleCloseTransferModal}
              message="Para qual departamento deseja transferir?"
              enableConfirmButton={selectedDepartment !== 'default'}
              actionName="Transferir"
              onConfirm={handleOnConfirmTransferTicket}
              confirmButtonColor={theme.secondaryColor}
            >
              <TransferTicketSection />
            </CustomDialog>
          </Tooltip>
          <Tooltip
            arrow
            title="Fechar chamado"
          >
            <button
              className="close-ticket-button"
              type="button"
              aria-label="Fechar chamado"
              onClick={handleOpenTicketModal}
            >
              <PhoneDisabledIcon />
            </button>

            <CustomDialog
              contactName={`${contactName}?`}
              open={openTicketModal}
              onClose={handleCloseTicketModal}
              onConfirm={handleCloseTicket}
              message="Tem certeza que deseja fechar o chamado com"
              actionName="Fechar"
              confirmButtonColor={theme.dangerButton}
            />
          </Tooltip>

          <Tooltip
            arrow
            title="Agendar mensagem"
          >
            <button
              type="button"
              aria-label="Agendar mensagem"
              onClick={handleOpenScheduleMessage}
            >
              <ScheduleSendIcon style={{ color: '#919191' }} />
            </button>
          </Tooltip>

          <PopOverMenu
            tooltipTitle="Adicionar mídias"
            icon={<AttachFileIcon style={{ color: '#919191' }} />}
          >

            <button
              type="button"
              aria-label="Botão de adicionar mídias"
              style={{
                width: '100%',
              }}
            >
              <ImageIcon style={{
                color: '#919191',
                marginRight: '15px',
              }}
              />
              <input
                type="file"
                id="inputFile"
                onChange={handleImageUpload}
                accept="image/*"
                multiple
                style={{
                  position: 'absolute',
                  left: '0',
                  top: '0',
                  width: '100%',
                  height: '100%',
                  zIndex: '1',
                  opacity: '0',
                }}
              />
              <h1>Enviar imagens</h1>
            </button>

            <button
              type="button"
              aria-label="Botão de adicionar vídeos"
              style={{
                width: '100%',
              }}
            >
              <VideocamIcon style={{
                color: '#919191',
                marginRight: '15px',
              }}
              />
              <input
                type="file"
                id="inputFile"
                onChange={handleVideoUpload}
                accept="video/*"
                multiple
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '40px',
                  zIndex: '1',
                  opacity: '0',
                }}
              />
              <h1>Enviar vídeos</h1>
            </button>
            <button
              type="button"
              aria-label="Botão de adicionar documentos"
              style={{
                width: '100%',
              }}
            >
              <InsertDriveFileIcon style={{
                color: '#919191',
                marginRight: '15px',
              }}
              />
              <input
                type="file"
                id="inputFile"
                onChange={handleDocumentsUpload}
                accept="*"
                multiple
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '40px',
                  zIndex: '1',
                  opacity: '0',
                }}
              />
              <h1>Enviar documentos</h1>
            </button>
          </PopOverMenu>

        </div>
      ) : (
        <div>
          <Tooltip
            arrow
            title="Transferir chamado"
          >
            <button
              type="button"
              aria-label="Tranferir chamado"
              className="transfer-ticket-button"
              onClick={handleOpenTransferModal}
            >
              <SyncAltIcon />
            </button>

            <CustomDialog
              open={openTransferModal}
              onClose={handleCloseTransferModal}
              message="Para qual departamento deseja transferir?"
              enableConfirmButton={selectedDepartment !== 'default'}
              actionName="Transferir"
              onConfirm={handleOnConfirmTransferTicket}
              confirmButtonColor={theme.secondaryColor}
            >
              <TransferTicketSection />
            </CustomDialog>
          </Tooltip>
          <PopOverMenu
            tooltipTitle="Transferir chamado"
            icon={<MoreVertIcon style={{ color: '#919191' }} />}
          >
            <div>

              <button
                type="button"
                aria-label="Fechar chamado"
                onClick={handleOpenTicketModal}
                style={{
                  borderBottom: '1px solid #CCC',
                  width: '100%',
                }}
              >
                <PhoneDisabledIcon
                  style={{
                    color: '#FF0F0F',
                    marginRight: '15px',
                  }}
                />
                <h1>Fechar chamado</h1>
              </button>

              <CustomDialog
                contactName={`${contactName}?`}
                open={openTicketModal}
                onClose={handleCloseTicketModal}
                onConfirm={handleCloseTicket}
                message="Tem certeza que deseja fechar o chamado com"
                actionName="Fechar"
                confirmButtonColor={theme.dangerButton}
              />

              <button
                type="button"
                aria-label="Botão de adicionar imagens"
                style={{
                  borderBottom: '1px solid #CCC',
                  width: '100%',
                }}
              >
                <ImageIcon style={{
                  color: '#919191',
                  marginRight: '15px',
                }}
                />
                <input
                  type="file"
                  id="inputFile"
                  onChange={handleImageUpload}
                  accept="image/*"
                  multiple
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '40px',
                    zIndex: '1',
                    opacity: '0',
                  }}
                />
                <h1>Enviar imagens</h1>
              </button>

              <button
                type="button"
                aria-label="Botão de adicionar vídeos"
                style={{

                  borderBottom: '1px solid #CCC',
                  width: '100%',
                }}
              >
                <VideocamIcon style={{
                  color: '#919191',
                  marginRight: '15px',
                }}
                />
                <input
                  type="file"
                  id="inputFile"
                  onChange={handleVideoUpload}
                  accept="video/*"
                  multiple
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '40px',
                    zIndex: '1',
                    opacity: '0',
                  }}
                />
                <h1>Enviar vídeos</h1>
              </button>
              <button
                type="button"
                aria-label="Botão de adicionar documentos"
                style={{
                  width: '100%',
                }}
              >
                <InsertDriveFileIcon style={{
                  color: '#919191',
                  marginRight: '15px',
                }}
                />
                <input
                  type="file"
                  id="inputFile"
                  onChange={handleDocumentsUpload}
                  accept="*"
                  multiple
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '40px',
                    zIndex: '1',
                    opacity: '0',
                  }}
                />
                <h1>Enviar documentos</h1>
              </button>

            </div>
          </PopOverMenu>
        </div>
      )}

      <ScheduleMessageDialog
        openScheduleMessage={openScheduleMessage}
        handleCloseScheduleMessage={handleCloseScheduleMessage}
        sender={currentChat?.sender}
        fromMsg={currentChat?.fromMsg}
      />

    </Container>
  );
}

ChatHeader.propTypes = {
  ipbxNumber: PropTypes.string.isRequired,
  contactName: PropTypes.string.isRequired,
  isOnline: PropTypes.bool.isRequired,
  attendantName: PropTypes.string,
  leadOrigin: PropTypes.string,
  profileImageURL: PropTypes.string,
};
