import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

h1 {
  font-size: 20px;
}

body {
  background: ${({ theme }) => theme.backgroundColor}
}

button {
  cursor: pointer;
  border: none;
}`;
