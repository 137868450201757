import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const KanbanHeader = styled.header`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  height: 64px;
  padding: 0px 20px;
  border-bottom: 1px solid #DDD;
  background-color: ${({ theme }) => theme.buttonBackground};

`;

export const HeaderButtons = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 20%;
  height: 100%;
  padding: 5px;
  background-color: transparent;
  transition: 0.2s;
  color: ${(props) => (props.isActive ? props.theme.cardKanbanTitle : props.theme.iconsColor)};
  border-bottom: 2px solid ${(props) => (props.isActive ? props.theme.cardKanbanTitle : 'transparent')};


  h2 {
    font-size: 20px;
    font-weight: 700;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  svg {
    margin-left: 5px;
    transition: 0.2s;
    color: ${(props) => (props.isActive ? props.theme.cardKanbanTitle : props.theme.iconsColor)};
  }

  &:hover {
    border-bottom: 2px solid #01374D;
    color: ${({ theme }) => theme.cardKanbanTitle};
    svg {
      color: ${({ theme }) => theme.cardKanbanTitle};
    }
  }
`;

export const KanbanMain = styled.main`
  height: 80%;
  width: 100%;
  padding: 20px;
  display: flex;
  overflow-x: auto;
  gap: 2%;

  h1 {
    font-size: 32px;
    color: ${({ theme }) => theme.secondaryFontColor};
    font-weight: 500;
    margin-top: 30px;
    text-align: center;
    align-self: center;
  }

  h3 {
    font-size: 20px;
    font-weight: normal;
  }

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    border-radius: 20px;
    border: 1px solid #AEBAC1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.secondaryColor};
    border-radius: 20px;
  }
`;

export const KanbanFooter = styled.div`
  height: 8%;
`;
