import { useEffect, useRef } from 'react';
import { patchRequest, baseUrl } from '../utils/services';

const useUserActivity = (userId, timeoutDuration = 120000) => {
  const lastRequestTimeRef = useRef(0);
  const debounceTimerRef = useRef(null);

  const updateLastOnline = async () => {
    const currentTime = Date.now();

    if (currentTime - lastRequestTimeRef.current >= timeoutDuration) {
      try {
        console.log('Updating last time online');
        await patchRequest(
          `${baseUrl}/users/online`,
          JSON.stringify({ userId }),
        );

        lastRequestTimeRef.current = currentTime;
      } catch (error) {
        console.error('Error updating last time online:', error);
      }
    }
  };

  const handleUserActivity = () => {
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }

    debounceTimerRef.current = setTimeout(() => {
      updateLastOnline();
    }, 3000);
  };

  useEffect(() => {
    const activityEvents = ['mousemove', 'keydown'];
    activityEvents.forEach((event) => window.addEventListener(event, handleUserActivity));

    return () => {
      activityEvents.forEach((event) => window.removeEventListener(event, handleUserActivity));

      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
    };
  }, []);

  return null;
};

export default useUserActivity;
