// styles.js
import styled from 'styled-components';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
} from '@mui/material';

export const StyledDialog = styled(Dialog)`
  width: 100%;
`;

export const StyledDialogTitle = styled(DialogTitle)`
  position: relative;
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  bottom: 5px;
  right: 8px;
`;

export const UserInfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  h4 {
    font-size: 18px;
    font-weight: 500;
  }
`;

export const UserAvatar = styled.img`
  margin-right: 10px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
`;

export const PrioritySection = styled.div`
  margin-top: 10px;
  h4 {
    margin-bottom: 5px;
  }
`;

export const PrioritySelect = styled.select`
  padding: 8px;
  border: 1px solid #AEBAC1;
  border-radius: 8px;
  font-size: 16px;
  width: 100%;
`;

export const CommentsSection = styled.div`
  max-height: 250px;
  overflow-y: auto;
  padding-right: 5px;

    &::-webkit-scrollbar {
    width: 10px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: rgba(0,0,0,0.2);
  }

  .comment-div {
    padding: 10px;
    border: 1px solid #AEBAC1;
    border-radius: 8px;
    margin: 10px 0;
  }
`;

export const CardTagSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  border: 1px solid #AEBAC1;
  border-radius: 8px;
  padding: 10px;
  margin-top: 10px;
`;

export const CardTag = styled.div`
  padding: 4px 8px;
  background-color: ${({ theme }) => theme.secondaryColor};
  border-radius: 16px;
  font-size: 12px;
  margin-bottom: 5px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  user-select: none;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const TextareaField = styled.textarea`
  padding: 8px;
  margin-top: 20px;
  border: 1px solid #AEBAC1;
  border-radius: 8px;
  font-size: 16px;
  width: 100%;
`;

export const AddCommentButton = styled.button`
  padding: 8px;
  margin: 10px 0;
  background-color: ${({ theme }) => theme.secondaryColor};
  color: #fff;
  border: none;
  transition: 0.2s;
  border-radius: 8px;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    filter: brightness(0.8);
  }
`;

export const StyledDialogActions = styled(DialogActions)`
  display: flex;
  justify-content: space-between;
  padding: 15px;
`;

export const ActionButtonsContainer = styled.div`
  width: 70%;
  display: flex;
  gap: 8px;
`;

export const DateText = styled.span`
  width: 25%;
  color: ${({ theme }) => theme.secondaryFontColor};
  margin-right: 10px;
`;

export const CommentAuthor = styled.span`
  font-weight: bold;
  display: block;
  margin-bottom: 4px;
`;
