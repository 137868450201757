import { useState } from 'react';

const useAlert = () => {
  const [alert, setAlert] = useState({
    open: false,
    message: '',
    severity: 'info',
    duration: 3000,
  });

  const showAlert = (message, severity = 'info', duration = 3000) => {
    setAlert({
      open: true,
      message,
      severity,
      duration,
    });
  };

  const hideAlert = () => {
    setAlert((prev) => ({ ...prev, open: false }));
  };

  return {
    alert,
    showAlert,
    hideAlert,
  };
};

export default useAlert;
