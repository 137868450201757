import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import { useContext, useState } from 'react';
import { useTheme } from '@emotion/react';
import { patchRequest, baseUrl } from '../../utils/services';
import { Container } from './styles';
import { AuthContext } from '../../context/AuthContext';
import { ChatContext } from '../../context/ChatContext';
import CustomDialog from '../CustomDialog';

export default function UserMenu() {
  const { user, logoutUser } = useContext(AuthContext);
  const {
    userChatsQueue,
    setCurrentChat,
    setIsEditingProfile,
    setUserChats,
    setOpenTickets,
    setSortedChats,
    setUserChatsQueue,
    handleCreateTicketClosedNotification,
    showAlert,
    getUserChatsQueue,
  } = useContext(ChatContext);

  const handleCloseAllTicketsInQueue = async () => {
    const userId = user.id;

    userChatsQueue.map((chatQueue) => {
      handleCreateTicketClosedNotification(
        chatQueue.sender,
        chatQueue.fromMsg,
        user.ambiente,
      );
    });

    const response = await patchRequest(`${baseUrl}/chats/closeAllTicketsInQueue`, JSON.stringify({ userId }));

    if (response.error) {
      return console.error('Erro ao fechar todos os chamados: ', response.error);
    }

    showAlert('Chamados encerrados com sucesso', 'success', 5000);
    getUserChatsQueue();
  };

  const handleLogoutClick = () => {
    logoutUser([]);
    setCurrentChat(null);
    setUserChats([]);
    setUserChatsQueue([]);
    setSortedChats([]);
    setOpenTickets([]);
  };

  const handleEditProfileClick = () => {
    setIsEditingProfile(true);
  };

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <Container>
      <button
        type="button"
        aria-label="Fechar todos chamados em fila"
        onClick={handleOpenModal}

      >
        <PhoneDisabledIcon
          fontSize="large"
          style={{
            color: '#FF0F0F',
          }}
        />
        <h1>Fechar todos chamados em fila</h1>
      </button>
      <button
        type="button"
        aria-label="Alterar senha"
        onClick={handleEditProfileClick}
      >
        <AccountCircleIcon
          style={{ color: '#919191' }}
          fontSize="large"
        />
        <h1>Alterar senha</h1>
      </button>

      <button
        type="button"
        aria-label="Encerrar sessão"
        onClick={handleLogoutClick}
      >
        <LogoutIcon
          style={{ color: '#919191' }}
          fontSize="large"
        />
        <h1>Sair</h1>
      </button>

      <CustomDialog
        open={openModal}
        onClose={handleCloseModal}
        onConfirm={handleCloseAllTicketsInQueue}
        message="Tem certeza que deseja encerrar"
        contactName="todos os chamados da fila?"
        actionName="Encerrar"
        confirmButtonColor="red"
      />
    </Container>
  );
}
