import styled from 'styled-components';

export const Container = styled.main`
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;

  .intro-modal-div {
    max-width: 50%;
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 70%;
      height: auto;
      margin: 30px 0 40px 0;
    }
  }

  header {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    span {
      font-family: 'montserrat', sans-serif;
      margin-top: 10px;
      font-weight: 500;
      color: #014F6D;
    }

    img {
      max-width: 100%;
      width: auto;
      height: auto;
    }
   }

   .login-form-div {
    width: 50%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, #000B16, #014F6D);

    .logo-chattelein {
      max-width: 60%;
      width: auto;
      height: auto;
      margin-bottom: 30px;
    }

    h1 {
      font-size: 26px;
      margin-bottom: 30px;
      font-weight: 500;
      color: #014F6D;
    }
}
`;

export const LoginForm = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 80px;
  background-color: white;
  border-radius: 20px;
  padding: 30px 30px;
  width: 60%;
  max-width: 400px;
  margin: 0 auto;

  .welcome-img {
    max-width: 350px;
  }

  .password-field {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .submit-login-button {
    padding: 12px;
    background-color: #014F6D;
    color: white;
    font-size: 16px;
    border-radius: 6px;
    transition: 0.2s;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    border: none;
  }

  button:hover {
    background-color: #0098d9;
  }

  p {
    color: ${({ theme }) => theme.dangerButton};
  }
`;
