import React, { useContext, useState } from 'react';
import {
  Container,
  FormSection,
  Input,
  Label,
  Checkbox,
  Button,
} from './NewKanbanBoardStyles';
import { ChatContext } from '../../context/ChatContext';
import { baseUrl, postRequest } from '../../utils/services';
import { AuthContext } from '../../context/AuthContext';
import { useErrors } from '../../context/ErrorContext';
import useAlert from '../../hooks/useAlert';
import CustomAlert from '../CustomAlert';

export default function NewKanbanCard() {
  const [boardName, setBoardName] = useState('');
  const [boardDescription, setBoardDescription] = useState('');
  const [columns, setColumns] = useState([{ name: '', position: 1 }]);
  const [isPublic, setIsPublic] = useState(false);
  const [shareWithAll, setShareWithAll] = useState(false);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [selectedAttendants, setSelectedAttendants] = useState([]);

  const { user } = useContext(AuthContext);

  const { departments, allUsers, setKanban } = useContext(ChatContext);

  const { setErrorMessage } = useErrors();

  const { alert, showAlert, hideAlert } = useAlert();

  const filteredAttendants = allUsers
    .filter((user) => user
      .setores
      .some((setor) => selectedDepartments.includes(setor.id_setor)));

  const handleColumnChange = (index, field, value) => {
    setColumns((prevColumns) => {
      const updatedColumns = [...prevColumns];

      if (field === 'position') {
        const positionExists = updatedColumns.some(
          (col, colIndex) => col.position === value && colIndex !== index,
        );

        if (positionExists) {
          showAlert('Essa posição já foi selecionada em outra coluna. Selecione uma nova', 'info', 5000);
          return prevColumns;
        }
      }

      updatedColumns[index] = {
        ...updatedColumns[index],
        [field]: value,
      };
      return updatedColumns;
    });
  };

  const addColumn = () => {
    setColumns([...columns, { name: '', position: columns.length + 1 }]);
  };

  const handleRemoveColumn = (index) => {
    const updatedColumns = columns.filter((_, idx) => idx !== index);
    setColumns(updatedColumns);
  };

  const updateAttendantsForDepartments = (departments) => {
    const attendantsInSelectedDepartments = allUsers.filter((user) => user
      .setores
      .some((setor) => departments.includes(setor.id_setor)));
    setSelectedAttendants(attendantsInSelectedDepartments.map((attendant) => attendant.id));
  };

  const handleDepartmentChange = (deptId) => {
    setSelectedDepartments((prevSelected) => {
      const isSelected = prevSelected.includes(deptId);
      const updatedDepartments = isSelected
        ? prevSelected.filter((id) => id !== deptId)
        : [...prevSelected, deptId];

      updateAttendantsForDepartments(updatedDepartments);
      return updatedDepartments;
    });
  };

  const handleShareWithAllChange = () => {
    setShareWithAll((prev) => !prev);
    if (!shareWithAll) {
      setSelectedAttendants(allUsers.map((user) => user.id));
    } else {
      setSelectedAttendants([]);
    }
  };

  const handleCreateNewKanbanBoard = async () => {
    if (boardName === '') return;

    const attendants = isPublic ? selectedAttendants : [user.id];

    const kanbanPayload = {
      name: boardName,
      description: boardDescription,
      columns,
      selectedAttendants: attendants,
      ambiente: user.ambiente,
      createdBy: user.id,
    };

    const resetNewKanbanForm = () => {
      setBoardName('');
      setBoardDescription('');
      setColumns([]);
      setIsPublic(false);
      setShareWithAll(false);
      setSelectedDepartments([]);
      setSelectedAttendants([]);
    };

    const response = await postRequest(`${baseUrl}/kanban/create`, JSON.stringify(kanbanPayload));

    if (response.error) {
      resetNewKanbanForm();
      return setErrorMessage('Erro ao criar quadro Kanban! Entre em contato com o suporte');
    }

    setKanban((prevKanban) => ({
      ...prevKanban,
      kanbanBoards: [...prevKanban.kanbanBoards, {
        id: response.boardId,
        name: boardName,
        description: boardDescription,
        ambiente: user.ambiente,
        createdBy: user.id,
      }],
    }));

    resetNewKanbanForm();

    showAlert('Quadro Kanban criado com sucesso', 'success', 5000);
  };

  return (
    <Container>
      <CustomAlert alert={alert} hideAlert={hideAlert} />
      <h2
        style={{
          fontWeight: '600',
          marginBottom: '10px',
        }}
      >
        Novo quadro Kanban
      </h2>

      <FormSection>
        <Label>Nome do quadro</Label>
        <Input
          type="text"
          value={boardName}
          onChange={(e) => setBoardName(e.target.value)}
          placeholder="Digite o nome do quadro"
        />
      </FormSection>

      <FormSection>
        <Label>Descrição</Label>
        <Input
          type="text"
          placeholder="Digite uma breve descrição do quadro"
          value={boardDescription}
          onChange={(e) => setBoardDescription(e.target.value)}
        />
      </FormSection>

      <FormSection>
        <Label>Colunas</Label>
        {columns.map((column, index) => (
          <div key={index} style={{ display: 'flex', marginBottom: '10px' }}>
            <Input
              type="text"
              placeholder="Nome da coluna"
              value={column.name}
              onChange={(e) => handleColumnChange(index, 'name', e.target.value)}
            />
            <Input
              type="number"
              placeholder="Ordem"
              value={column.position}
              onChange={(e) => handleColumnChange(index, 'position', parseInt(e.target.value, 10))}
              style={{ width: '80px', marginLeft: '10px' }}
            />
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => handleRemoveColumn(index)}
              style={{ marginLeft: '10px' }}
            >
              Remover
            </Button>
          </div>
        ))}
        <Button type="button" onClick={addColumn}>Adicionar coluna</Button>
      </FormSection>

      <FormSection>
        <Label>Privacidade</Label>
        <div>
          <Checkbox
            type="radio"
            checked={!isPublic}
            onChange={() => setIsPublic(false)}
          />
          Privado
          <Checkbox
            type="radio"
            checked={isPublic}
            onChange={() => setIsPublic(true)}
            style={{
              marginLeft: '10px',
            }}
          />
          Público
        </div>
      </FormSection>

      {isPublic && (
        <>
          <FormSection>
            <Checkbox
              type="checkbox"
              checked={shareWithAll}
              onChange={handleShareWithAllChange}
            />
            Compartilhar com todos os usuários do ambiente
          </FormSection>

          {!shareWithAll && (
            <>
              <FormSection>
                <Label>Departamentos disponíveis</Label>
                {departments.map((dept) => (
                  <div key={dept.id}>
                    <Checkbox
                      type="checkbox"
                      checked={selectedDepartments.includes(dept.id)}
                      onChange={() => handleDepartmentChange(dept.id)}
                    />
                    {dept.nome_setor}
                  </div>
                ))}
              </FormSection>

              <FormSection>
                <Label>Atendentes dos departamentos selecionados</Label>
                {filteredAttendants
                  .filter((attendant) => attendant
                    .setores
                    .some((setor) => selectedDepartments.includes(setor.id_setor)))
                  .map((attendant) => (
                    <div key={attendant.id}>
                      <Checkbox
                        type="checkbox"
                        checked={selectedAttendants.includes(attendant.id)}
                        onChange={() => setSelectedAttendants((prev) => (prev.includes(attendant.id)
                          ? prev.filter((id) => id !== attendant.id)
                          : [...prev, attendant.id]))}
                      />
                      {attendant.name}
                    </div>
                  ))}
              </FormSection>
            </>
          )}
        </>
      )}

      <Button
        onClick={handleCreateNewKanbanBoard}
        type="submit"
      >
        Criar quadro Kanban
      </Button>
    </Container>
  );
}
