import PropTypes from 'prop-types';
import React, { useState, useContext } from 'react';
import {
  Tooltip,
  DialogContent,
  IconButton,
  useTheme,
} from '@mui/material';
import moment from 'moment';
import ChatIcon from '@mui/icons-material/Chat';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import ArchiveIcon from '@mui/icons-material/Archive';
import CloseIcon from '@mui/icons-material/Close';
import { ChatContext } from '../../../context/ChatContext';
import {
  baseUrl, formatPhoneNumber, patchRequest, postRequest,
  stripPrefix,
} from '../../../utils/services';

import {
  StyledDialog,
  StyledDialogTitle,
  CloseButton,
  UserInfoContainer,
  UserAvatar,
  StyledDialogActions,
  ActionButtonsContainer,
  DateText,
  PrioritySection,
  PrioritySelect,
  CommentsSection,
  TextareaField,
  AddCommentButton,
  CommentAuthor,
  CardTagSection,
  CardTag,
} from './styles';
import { AuthContext } from '../../../context/AuthContext';
import { useErrors } from '../../../context/ErrorContext';

function CardDetailsDialog({
  open,
  onClose,
  card,
  openChatFunc,
  openScheduleMessageFunc,
  archiveChatFunc,
}) {
  const { allUsers, setKanban } = useContext(ChatContext);
  const { user } = useContext(AuthContext);

  const [comment, setComment] = useState('');
  const [priority, setPriority] = useState(card.priority || 'baixa');

  const { setErrorMessage } = useErrors();

  const theme = useTheme();

  if (!card) return null;

  const handlePriorityChange = async (event) => {
    const newPriority = event.target.value;
    setPriority(newPriority);

    const numericCardId = stripPrefix(card.id);

    const response = await patchRequest(
      `${baseUrl}/kanban/updateCardPriority/${Number(numericCardId)}`,
      JSON.stringify({ priority: newPriority }),
    );

    if (response.error) {
      return setErrorMessage('Não foi possível atualizar prioridade! Entre em contato com o suporte');
    }

    setKanban((prev) => ({
      ...prev,
      currentKanban: {
        ...prev.currentKanban,
        cards: {
          ...prev.currentKanban.cards,
          [card.id]: {
            ...prev.currentKanban.cards[card.id],
            priority: newPriority,
          },
        },
      },
    }));
  };

  const handleOpenChat = () => {
    openChatFunc();
  };
  const handleScheduleMessage = () => {
    openScheduleMessageFunc();
  };
  const handleArchiveConversation = () => {
    archiveChatFunc();
  };

  const handleAddComment = async () => {
    const numericCardId = stripPrefix(card.id);

    const commentPayload = {
      cardId: Number(numericCardId),
      userId: user.id,
      comment_text: comment,
    };

    const response = await postRequest(`${baseUrl}/kanban/createComment`, JSON.stringify(commentPayload));

    if (response.error) {
      return setErrorMessage('Erro ao criar comentário! Entre em contato com o suporte');
    }

    setComment('');

    const newComment = {
      text: comment,
      createdAt: Date.now(),
      user_id: user.id,
    };

    setKanban((prev) => ({
      ...prev,
      currentKanban: {
        ...prev.currentKanban,
        cards: {
          ...prev.currentKanban.cards,
          [card.id]: {
            ...prev.currentKanban.cards[card.id],
            content: {
              ...prev.currentKanban.cards[card.id].content,
              comments: [...prev.currentKanban.cards[card.id].content.comments, newComment],
            },
          },
        },
      },
    }));
  };

  const getUserNameById = (userId) => {
    const user = allUsers.find((user) => user.id === userId);

    return user.name;
  };

  const avatarUrl = card.content.profilePic || 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/1024px-User-avatar.svg.png?20201213175635';

  return (
    <StyledDialog open={open} onClose={onClose} fullWidth>
      <StyledDialogTitle>
        <CloseButton aria-label="Fechar" onClick={onClose}>
          <CloseIcon />
        </CloseButton>
        <UserInfoContainer>
          <UserAvatar src={avatarUrl} alt="Perfil do usuário" />
          <div>
            <h3>{card.content.name}</h3>
            {!card.content.name.startsWith('+')
            && (
              <h4>{formatPhoneNumber(card.content.phone)}</h4>
            )}
          </div>
        </UserInfoContainer>
        <PrioritySection>
          <h4>Prioridade:</h4>
          <PrioritySelect
            value={priority}
            onChange={handlePriorityChange}
          >
            <option value="baixa">Baixa</option>
            <option value="media">Media</option>
            <option value="alta">Alta</option>
          </PrioritySelect>
        </PrioritySection>
      </StyledDialogTitle>

      <DialogContent dividers>
        <CommentsSection>
          <h3>Comentários:</h3>
          {card.content.comments.length > 0
            ? (
              card.content.comments.map((comment, index) => (
                <div
                  className="comment-div"
                  key={index}
                  style={{
                    textAlign: comment.user_id === user.id
                      ? 'end'
                      : 'start',
                    backgroundColor: comment.user_id === user.id
                      ? 'transparent'
                      : theme.inputBackground,
                  }}
                >
                  <CommentAuthor>
                    {getUserNameById(comment.user_id)}
                  </CommentAuthor>
                  <p>{comment.text}</p>
                </div>
              ))
            )
            : (
              <p>Nenhum comentário encontrado.</p>
            )}

        </CommentsSection>
        <TextareaField
          rows={4}
          placeholder="Adicione um comentário..."
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        <AddCommentButton
          aria-label="Adicionar comentário"
          type="button"
          disabled={comment === ''}
          onClick={handleAddComment}
        >
          Adicionar
        </AddCommentButton>

        <h3>Tags associadas</h3>
        <CardTagSection>
          {card.content.tags.map((tag) => (
            <Tooltip
              placement="top"
              arrow
              title={`Tag: ${tag.tag_name}`}
            >
              <CardTag>
                {tag.tag_name}
              </CardTag>
            </Tooltip>
          ))}
        </CardTagSection>
      </DialogContent>

      <StyledDialogActions>
        <ActionButtonsContainer>
          <Tooltip
            title="Abrir conversa"
            placement="top"
            arrow
          >
            <IconButton onClick={handleOpenChat}>
              <ChatIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            title="Agendar mensagem"
            placement="top"
            arrow
          >
            <IconButton onClick={handleScheduleMessage}>
              <ScheduleSendIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            title="Arquivar conversa"
            placement="top"
            arrow
          >
            <IconButton onClick={handleArchiveConversation}>
              <ArchiveIcon />
            </IconButton>
          </Tooltip>
        </ActionButtonsContainer>

        <DateText>
          {moment(card.updatedAt).format('DD/MM/YYYY HH:mm')}
        </DateText>
      </StyledDialogActions>
    </StyledDialog>
  );
}

export default CardDetailsDialog;

CardDetailsDialog.propTypes = {
  openScheduleMessageFunc: PropTypes.func.isRequired,
  archiveChatFunc: PropTypes.func.isRequired,
  openChatFunc: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  card: PropTypes.shape({
    id: PropTypes.string.isRequired,
    priority: PropTypes.string.isRequired,
    content: PropTypes.shape({
      comments: PropTypes.arrayOf(
        PropTypes.shape({
          createdAt: PropTypes.string.isRequired,
          text: PropTypes.string.isRequired,
        }),
      ).isRequired,
      name: PropTypes.string.isRequired,
      phone: PropTypes.string.isRequired,
      profilePic: PropTypes.string,
      tags: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          tag_name: PropTypes.string.isRequired,
          ambiente: PropTypes.number.isRequired,
        }),
      ).isRequired,
    }).isRequired,
    updatedAt: PropTypes.string.isRequired,
  }).isRequired,
};
