import PropTypes from 'prop-types';
import { useState } from 'react';
import { Tooltip } from '@mui/material';
import { useTheme } from 'styled-components';
import CustomDialog from '../CustomDialog';
import { StyledButton } from './styles';

export default function CloseAllTicketsButton({
  icon,
  tooltip,
  closeAllTicketsFunc,
}) {
  const [openModal, setOpenModal] = useState(false);

  const theme = useTheme();

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <Tooltip
      arrow
      title={tooltip}
      placement="top"
    >
      <StyledButton
        onClick={handleOpenModal}
      >
        {icon}
      </StyledButton>

      <CustomDialog
        open={openModal}
        onClose={handleCloseModal}
        onConfirm={closeAllTicketsFunc}
        message="Tem certeza que deseja encerrar todos"
        contactName="chamados em aberto?"
        actionName="Encerrar"
        confirmButtonColor={theme.dangerButton}
      />
    </Tooltip>
  );
}

CloseAllTicketsButton.propTypes = {
  closeAllTicketsFunc: PropTypes.func.isRequired,
  tooltip: PropTypes.string.isRequired,
  icon: PropTypes.func.isRequired,
};
