import styled from 'styled-components';

export const ContainerSchedule = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;

  .scheduled-messages-wrapper {
      margin: 0 0 10px 0;
      width: 100%;
      height: 300px;
      overflow-y: auto;
      padding-right: 10px;

      &::-webkit-scrollbar {
        width: 10px;
        height: 6px;
        border-radius: 20px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 20px;
        background-color: rgba(0,0,0,0.2);
      }
    }

    .close-scheduled-messages-button {
      background-color: transparent;
      border-radius: 50%;
      margin-bottom: 10px;
      padding: 5px;
      transition: 0.2s;

      &:hover {
      background-color: ${({ theme }) => theme.hoverButtonBackground};
    }
    }

    .scheduled-message-div {
      padding: 10px;
      width: 300px;
      max-height: 200px;
      height: 70px;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: space-between;
      border-radius: 8px;
      margin-bottom: 10px;
      box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
      background-color: ${({ theme }) => theme.buttonBackground};
      position: relative;

      h3 {
        font-size: 16px;
      }

      p {
        width: 100%;
        height: 100%;
        padding: 0 10px 10px 0;
        display: flex;
        align-items: start;
        justify-content: start;
        text-overflow: ellipsis;
        text-align: left;
        overflow-x: visible;
        overflow-y: hidden;
        white-space: normal;
        word-break: break-word;
      }
    }

    .cancel-scheduled-message-button {
      position: absolute;
      top: 0;
      right: 0;
      height: 20px;
      margin-right: 5px;
      transition: 0.2s;
      font-size: 18px;
      background-color: transparent;

      &:hover {
        font-weight: 700;

      }
    }

  .already-scheduled-button {
    font-weight: 500;
    padding: 10px;
    color: ${({ theme }) => theme.buttonBackground};
    background-color: ${({ theme }) => theme.secondaryColor};
    margin-bottom: 30px;
    border-radius: 12px;
    transition: 0.2s ease;

    &:hover {
      filter: brightness(0.8);
    }
  }
`;
