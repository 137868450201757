import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #EDEDED;

  .session-conflict-modal {
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 100%;
    max-width: 400px;

    h2 {
      color: #014F6D;
      margin-bottom: 20px;
    }

    p {
      color: #333;
      margin-bottom: 30px;
    }

    button {
      background-color: #014F6D;
      color: #fff;
      border: none;
      border-radius: 5px;
      padding: 10px 15px;
      cursor: pointer;
      margin: 10px 5px;
      width: 100%;
      max-width: 200px;
      font-size: 16px;
    }

    button:hover {
      background-color: #013a52;
    }
  }
`;
