// CustomAlert.js (Custom Component)
import React from 'react';
import PropTypes from 'prop-types';
import { Snackbar, Alert } from '@mui/material';

export default function CustomAlert({ alert, hideAlert }) {
  return (
    <Snackbar
      open={alert.open}
      autoHideDuration={alert.duration}
      onClose={hideAlert}
    >
      <Alert
        onClose={hideAlert}
        severity={alert.severity}
        variant="filled"
        sx={{ width: '100%' }}
      >
        {alert.message}
      </Alert>
    </Snackbar>
  );
}

CustomAlert.propTypes = {
  alert: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    severity: PropTypes.oneOf(['success', 'error', 'warning', 'info']).isRequired,
    duration: PropTypes.number.isRequired,
  }).isRequired,
  hideAlert: PropTypes.func.isRequired,
};
