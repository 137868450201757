import { useContext, useState } from 'react';
import { CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import { Container } from './styles';
import { ChatContext } from '../../context/ChatContext';
import { useErrors } from '../../context/ErrorContext';

export default function SendMedias({
  urlMediaTosend = null,
  setMediaToSendFunc,
  setSelectedMediaToSendFunc,
  type,
}) {
  const {
    isImageUploadLoading,
    isMessagesLoading,
    selectedDocumentToSend,
  } = useContext(ChatContext);

  const [activeMediaIndex, setActiveMediaIndex] = useState(null);
  const [selectedMediaUrl, setSelectedMediaUrl] = useState(null);

  const { setErrorMessage } = useErrors();

  const handleCancelMediaToSend = () => {
    setMediaToSendFunc([]);
    setSelectedMediaToSendFunc(null);
  };

  const handleActiveMedia = (index, mediaUrl) => {
    setActiveMediaIndex(index);
    setSelectedMediaUrl(mediaUrl);
  };

  const handleMediaUpload = (e) => {
    const { files } = e.target;
    const existingMediaUrls = urlMediaTosend || [];

    const newFiles = Array.from(files)
      .filter((file) => !existingMediaUrls.some((url) => url.includes(file.name)));

    const totalMedias = existingMediaUrls.length + newFiles.length;
    if (totalMedias > 10) {
      return setErrorMessage('Limite máximo de 10 imagens simultâneas por envio atingido!');
    }

    return setSelectedMediaToSendFunc((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const getMediaPreview = (mediaUrl) => {
    if (type === 'image') return mediaUrl;
    if (type === 'document') return 'https://icons.veryicon.com/png/o/system/dan_system/file-60.png';

    return 'https://img.icons8.com/?size=100&id=111348&format=png&color=000000';
  };

  const getAcceptedFiles = () => {
    if (type === 'image') return 'image/*';
    if (type === 'video') return 'video/mp4';

    return '*';
  };

  const handleDeleteMedia = (event) => {
    try {
      event.stopPropagation();
      const updatedMedia = [...urlMediaTosend];

      setMediaToSendFunc(updatedMedia);
      if (updatedMedia.length === 0) {
        handleCancelMediaToSend();
      }
    } catch (error) {
      setErrorMessage('Algo deu errado! Entre em contato com o suporte.');
    }
  };

  return (
    <Container>
      <button
        type="button"
        aria-label="cancelar envio de imagem"
        onClick={handleCancelMediaToSend}
        className="cancel-images-to-send-button"
      >
        <CloseIcon style={{
          color: '#919191',
          fontSize: '30px',
        }}
        />
      </button>
      <div className="send-media-container">

        {type === 'image' && !isMessagesLoading && (
          <img
            className="main-image"
            src={activeMediaIndex === null
              ? urlMediaTosend && urlMediaTosend[0]
              : selectedMediaUrl}
            alt="Imagem a ser enviada pelo usuário"
          />
        )}
        {type === 'video' && !isMessagesLoading && (
        <video
          className="send-media-container"
          src={activeMediaIndex === null
            ? urlMediaTosend && urlMediaTosend[0]
            : selectedMediaUrl}
          controls
          style={{ width: '30%', height: '30vh' }}
          autoPlay
        >
          <track kind="captions" srcLang="pt" label="Portuguese captions" />
        </video>
        )}

        {type === 'document' && !isMessagesLoading && (
          selectedDocumentToSend[0].type.startsWith('audio') ? (
            <audio controls>
              <source
                src={activeMediaIndex === null
                  ? urlMediaTosend && urlMediaTosend[0]
                  : selectedMediaUrl}
                type={selectedDocumentToSend[0].type}
              />
              <track kind="captions" src="" />
            </audio>
          )
            : (
              <embed
                type="*"
                className="send-media-container"
                src={activeMediaIndex === null
                  ? urlMediaTosend && urlMediaTosend[0]
                  : selectedMediaUrl}
                style={{ width: '50%', height: '60vh' }}
              />
            )
        )}

        {
          isMessagesLoading && (
            <CircularProgress variant="indeterminate" />
          )
        }

        {isImageUploadLoading && (
        <CircularProgress variant="indeterminate" />
        )}
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        >
          <div className="preview-images">
            <div className="images-container">
              {
                urlMediaTosend?.map((mediaUrl, index) => (
                  <button
                    type="button"
                    onClick={() => handleActiveMedia(index, mediaUrl)}
                    aria-label="Botão de prévia da mídia"
                    className="preview-images-button"
                    key={index}
                  >
                    <button
                      type="button"
                      aria-label="Excluir prévia da mídia para envio"
                      className="delete-preview-image-button"
                      onClick={(e) => handleDeleteMedia(e, index)}
                    >
                      <CloseIcon style={{
                        color: '#919191',
                        fontSize: '15px',
                      }}
                      />
                    </button>

                    <img
                      style={{
                        border: activeMediaIndex === index
                          ? '2px solid #014F6D'
                          : '1px solid #AEBAC1',
                      }}
                      src={getMediaPreview(mediaUrl)}
                      alt="imagens enviadas pelo usuário"
                    />

                  </button>

                ))
}
            </div>
            <button
              type="button"
              aria-label="selecionar mais imagens para envio"
              className="more-images-button"
            >
              <AddIcon style={{
                color: '#919191',
                fontSize: '30px',
              }}
              />
              <input
                type="file"
                id="inputFile"
                accept={getAcceptedFiles}
                multiple
                onChange={handleMediaUpload}
                style={{
                  position: 'absolute',
                  left: '0',
                  top: '0',
                  width: '100%',
                  height: '100%',
                  zIndex: '1',
                  opacity: '0',
                }}
              />
            </button>
          </div>
        </div>
      </div>

    </Container>
  );
}

SendMedias.propTypes = {
  urlMediaTosend: PropTypes.arrayOf(PropTypes.string),
  setMediaToSendFunc: PropTypes.func.isRequired,
  setSelectedMediaToSendFunc: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};
