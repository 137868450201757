import { useContext, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { AuthContext } from '../../context/AuthContext';
import Sidebar from '../../components/Sidebar';
import ChatWindow from '../../components/ChatWindow';
import EditProfileChat from '../../components/EditProfileChat';
import ErrorAlert from '../../components/ErrorAlert';
import useUserActivity from '../../hooks/useUserActivity';
import { Container } from './styles';
import { baseUrl, patchRequest, formatPhoneNumber } from '../../utils/services';
import { ChatContext } from '../../context/ChatContext';
import SessionConflictModal from '../../components/SessionConflictModal';

export default function Chat() {
  const {
    user,
    logoutUser,
  } = useContext(AuthContext);

  const {
    socket,
    currentChat,
    setOnlineUsers,
    isEditingProfileChat,
    sessionConflict,
    setSessionConflict,
  } = useContext(ChatContext);

  const { sessionConflictData, showSessionConflict } = sessionConflict;

  const handleContinueSession = () => {
    socket.emit('forceLogoutOthers', user?.id, user?.ambiente);
    socket.emit('endOtherSession', sessionConflictData.existingSession);
    socket.emit('addNewUser', user?.id, user?.senders, user?.ambiente);
    socket.on('getOnlineUsers', (res) => {
      setOnlineUsers(res);
    });

    setSessionConflict({
      showSessionConflict: false,
      sessionConflictData: null,
    });
  };

  const handleStayInOldSession = () => {
    logoutUser();
    setSessionConflict({
      showSessionConflict: false,
      sessionConflictData: null,
    });
  };

  useUserActivity(user.id, 30000);

  const contactName = currentChat?.customName
    || currentChat?.nome
    || formatPhoneNumber(currentChat?.fromMsg);

  return (
    <Container>
      <ErrorAlert />
      {/*
      showSessionConflict ? (
        <SessionConflictModal
          onContinueSession={handleContinueSession}
          onStayInOldSession={handleStayInOldSession}
        />
      )
        : ( */
        <DndProvider backend={HTML5Backend}>
          <Sidebar profileImageURL={user?.imageUrl} />
          <ChatWindow />
          {isEditingProfileChat && currentChat && (
            <EditProfileChat
              profileImageURL={currentChat.profilePic}
              contactName={contactName}
              contactPhone={currentChat.fromMsg}
            />
          )}
        </DndProvider>
        /*
        ) */
}

    </Container>
  );
}
