import { useState } from 'react';

const usePhoneFormatter = (initialValue = '') => {
  const [phone, setPhone] = useState(initialValue);

  const formatPhoneNumber = (phoneNumber) => {
    const countryCode = phoneNumber.slice(0, 2);
    const stateCode = phoneNumber.slice(2, 4);
    const mainNumber = phoneNumber.slice(4);

    let formatted = `+${countryCode} (${stateCode})`;

    if (mainNumber.length > 0) {
      formatted += ` ${mainNumber.replace(/(\d{5})(\d+)/, '$1-$2')}`;
    }

    return formatted;
  };
  const handlePhoneChange = (event) => {
    const { value } = event.target;
    let rawPhone = value.replace(/[^\d]/g, '');

    if (!rawPhone.startsWith('55')) {
      rawPhone = `55${rawPhone}`;
    }

    const formattedPhone = formatPhoneNumber(rawPhone);

    setPhone(formattedPhone);
  };

  const getRawPhoneNumber = () => phone.replace(/[^\d]/g, '');

  return [phone, handlePhoneChange, setPhone, getRawPhoneNumber];
};

export default usePhoneFormatter;
